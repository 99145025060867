<template>
    <div id="app">
        <div id="loading-wrapper">
            <div id="loading-text">
                Coming soon
            </div>
            <div id="loading-content" />
        </div>
    </div>
</template>
<style lang="scss">
    #loading-wrapper {
        position : fixed;
        width    : 100%;
        height   : 100%;
        left     : 0;
        top      : 0;
    }

    #loading-text {
        display     : block;
        position    : absolute;
        top         : 50%;
        left        : 50%;
        color       : #212529;
        width       : 140px;
        height      : 30px;
        margin      : -10px 0 0 -70px;
        text-align  : center;
        font-family : "PT Sans Narrow", sans-serif;
        font-size   : 20px;
    }

    #loading-content {
        display  : block;
        position : relative;
        left     : 50%;
        top      : 50%;
        width    : 170px;
        height   : 170px;
        margin   : -85px 0 0 -85px;
    }

    #loading-content {
        border              : 3px solid transparent;
        border-top-color    : #f9e07f;
        border-bottom-color : #f9e07f;
        border-radius       : 50%;
        -webkit-animation   : loader 2s linear infinite;
        -moz-animation      : loader 2s linear infinite;
        -o-animation        : loader 2s linear infinite;
        animation           : loader 2s linear infinite;
    }

    @keyframes loader {
        0% {
            -webkit-transform : rotate(0deg);
            -ms-transform     : rotate(0deg);
            transform         : rotate(0deg);
        }

        100% {
            -webkit-transform : rotate(360deg);
            -ms-transform     : rotate(360deg);
            transform         : rotate(360deg);
        }
    }
</style>
