import {createStore} from "vuex";

// Import modules
import Home from "./Home";

export default createStore({
    modules: {
        Home,
    },
});
